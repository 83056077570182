import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Header />

    <section className="intro hero">
      <div className="hero-body">
        <div className="container" style={{ maxWidth: 800 }}>

          <h1 className="title">Hi, I'm Francisco.</h1>

        </div>
      </div>
    </section>
    {/* 
    <section className="section">
      <div className="container">
        <p className="title">Recent work</p>
        <div className="columns is-multiline is-centered is-6 is-variable">
          {allProjects.map(project => (
            <div className="column is-two-fifths" key={project.name}>
              <Project {...project} />
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <p className="title">Hire me</p>
        <p className="subtitle">
          Looking for an experienced developer?
          <br />
          I'd love to help in a professional, communicative and punctual manner.
          <br />
          Send me an email: <EmailMe />
        </p>
      </div>
    </section> */}
  </Layout>
)

export default IndexPage
