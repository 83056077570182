import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EmailMe from "./emailMe"

const Header = props => (
  <StaticQuery
    query={graphql`
      query {
        fran: file(relativePath: { eq: "yo2.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <nav
        className="navbar is-spaced"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item profile" href="https://franciscovg.com">
            <div
              className="profile-image"
              style={{
                maxWidth: 100,
                maxHeight: 100,
              }}
            >
              <Img
                alt="Francisco Vera"
                fluid={data.fran.childImageSharp.fluid}
                className="rounded"
              />
            </div>
            <div className="profile-text">
              <h6 className="title is-6">Francisco Vera</h6>
            </div>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-end">
            <div className="navbar-item">
              <EmailMe />
            </div>
          </div>
        </div>
      </nav>
    )}
  />
)
export default Header
